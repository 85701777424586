import { useEffect, useState } from 'react';

import auth from '../../service/auth';

function Organizations() {

  const showfields=["name","enabled"];
  const required =["name"];
  const hides = ["created_at","updated_at"];
  const excludes = ["enabled","created_at","updated_at","organization_id"];
  const [organizations, setOrganizations] = useState([]);
  const [fields, setFields] = useState(required);
  const [fieldsNew, setFieldsNew] = useState(required);


const gets= async () => {
  const result = await  auth.api( 'organizations');


  setOrganizations(result);
  if(result.length==0) return;

  setFields(Object.keys(result[0]).filter(field=>!hides.includes(field)))
  setFieldsNew(Object.keys(result[0]).filter(field=>!excludes.includes(field)))


};

const  remove = async (id)=>{
  const result = await  auth.api( 'organization/' + id,{
    method:"DELETE"
 

  });

  await gets();


}

const create = async ()=>{
  
var organization = {};
 fieldsNew.forEach(field=>{
var value = document.getElementById(field).value;
if(value) organization[field] = value;
 })
var required = ["name"];
organization.complete=true;
required.forEach(field=> {
if (!(field in organization)) organization.complete=false;
});
  
 //  if(!organization.completed )return; 
 const result = await  auth.api( 'organization',{
    method:"PUT",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(organization)
  });

  await gets();
  fieldsNew.forEach(field=>{
   document.getElementById(field).value="";
   
     })
}

  

  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">ORGANIZATIONS</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th  key={field}  className='p-2 mx-4'> {field}  </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>
            {organizations.map(organization =>
              <tr   key={organization.organization_id}  className='p-2'>
                {showfields.map(field => <td  key={field}  className='p-2' > {organization[field]}  </td>)}


<td>
<button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-1 rounded'>Editer</button>
<button onClick={()=>remove(organization.organization_id)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button>

</td>


              </tr>

            )

            }


<tr >
{showfields.map(field => 
<td   key={field}  className=''> { 
   fieldsNew.includes(field) ? <input className='shadow organizationearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'  type="text" name={field}  id={field}  placeholder={field} />  :<></>  }  </td>)} 
   
   
   
<td className='p-1'>
<button onClick={create} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Ajouter</button>

</td>
   
    </tr>         
 
          </tbody>
        </table>


      </header>
    </div>
  );
}

export default Organizations;
