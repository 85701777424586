import Content from "./Content"

const { default: Icon } = require("./Icon")

const RenderInputField = ({element,id,field,lists,changeProps,darkMode})=>{

    switch(field){
        case "content":
            return <Content content={element[field]}  icon={element.icon} setContent={  (value)=>changeProps(id,field,value)  } send_at={element.send_at}  darkMode={darkMode} />
            case "enabled":
                return   <input type="checkbox"  checked={ element[field]==true} onChange={  (e)=>changeProps(id,field,e.target.checked)} />
    case "icon":
    
     return <Icon icon={ element[field]} setIcon={ element.type=="app"? (value)=>changeProps(id,field,value) :null } name={"newIcon"} size={ element.type=="app"? 20 :8}  />
    default:
    if ( (field+"s") in lists)
    return <select  className='shadow elementearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'  id={(id ? id:"")+field+"_id"} placeholder={field}  onChange={element ?  (e)=>changeProps(id,field+"_id",e.target.value) :null } >
   {lists[field+"s"].map(el=>
       <option key={el[field+"_id"]} value={el[field+"_id"]}  >{el.name}</option>
   )}
    </select> 
    else 
     return <input className='shadow elementearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' defaultValue={id ? element[field]: ""} type="text" id={(id ?id:"")+field} placeholder={field} onChange={id ? (e)=>changeProps(id,field,e.target.value) :null } />

    }
    
    }
    
 const RenderField = ({element,id,field,editable,lists,changeProps,darkMode})=>{
if (editable )
return <RenderInputField  element={element} field={field} lists={lists} changeProps={changeProps} id={id} send_at={element.send_at} darkMode={darkMode}/>

switch(field){
    case "enabled":
        return   <input type="checkbox"  checked={ element[field]==true} disabled />
    case "content":
 return <Content content={element[field]}  icon={element.icon}  send_at={element.send_at} darkMode={darkMode}  />
case "icon":

 return <Icon icon={element[field]}   size={ element.type=="app"? 20 :8}  />
default:

 return <>{ element[field]  }</>
}

}



export default  RenderField;