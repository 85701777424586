import { useEffect, useState } from 'react';
import AppCode from '../../components/AppCode';

import  RenderField  from '../../components/RenderField';
import auth from '../../service/auth';
import mapList from '../../service/mapList';

function Applications() {
const showfields=["icon","name","organization","enabled","push_api_key"];
  const required = ["name", "organization_id"];

  const excludes = ["app_id", "enabled", "created_at", "updated_at"];
  const [apps, setApps] = useState([]);

  const [fieldsNew, setFieldsNew] = useState(required);

 const [newApp,setnewApp]=useState(null)
const [lists,setLists]=useState([]);
const [appCode,setAppCode]=useState(null);
  const gets = async () => {
    const resultLists = await auth.api('lists');


    var result = await auth.api('apps');
   
result = mapList(result,resultLists,"app");
setLists(resultLists);
    setApps(result);
    if (result.length == 0) return;

  
    setFieldsNew(Object.keys(result[0]).filter(field => !excludes.includes(field)))


  };



  const remove = async (id) => {
    const response = await auth.api('app/' + id, {
      method: "DELETE"
    });

    await gets();

  }

  const changeProps=(id,field,value)=>{
    if(!id) {
 console.log("SET IN NEWapp " ,field,value)
      newApp[field]=value;
      setnewApp({...newApp} )
      return; 
    }
   setApps( apps.map(app=>{
    if(app.app_id==id) app[field]=value;
   
  
  return app;
  }));
console.log(id,field,value,apps);
  }


const showCode=(app)=>{
setAppCode({...app});

}


  const create = async () => {

    var app =newApp;
    fieldsNew.forEach(field => {
  
      var obj =document.getElementById(field);
      console.log(field, obj)
      if(!obj) return;
      var value = obj.value;
      if (value) app[field] = value;
    })
    var required = ["name"];
    app.complete = true;
    required.forEach(field => {
      if (!(field in app)) app.complete = false;
    });

    if(!app.complete )return; 
    const response = await auth.api('app', {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(app)
    });

    await gets();
 
    setnewApp(null)

  }


   const saveObj = async (app) => {


    var required = ["name"];
    var completed = true;
    required.forEach(field => {
      if (!(field in app)) completed = false;
    });

    if(!completed )return; 

    const response = await auth.api('app', {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(app)
    });

    changeProps(app.app_id,"editable",false);

  }


  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">APPLICATIONS</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th key={field} className='p-2 mx-4'> {field}  </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>

          <tr >
              {showfields.map(field =>
                <td key={field} className=''> {
                  fieldsNew.includes(field) && newApp ? 
                  <>
                    <RenderField element={newApp} field={field} editable={true} lists={lists} changeProps={changeProps}  />
                  </>
                 : <></>}  </td>)}



              <td className='p-1'>
              
                {
 newApp ?
<>  <button onClick={()=>setnewApp(null)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded '>Annuler</button>
<button onClick={create} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded mx-1'>Valider</button>
</>
 :  <button  onClick={()=>setnewApp({type:"app"})}  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Créer</button>

}
              </td>

            </tr>
            {apps.map(app =>
              <tr key={app.app_id} className='p-2'>
                {showfields.map(field => <td key={field} className='p-2' >
              
                 <RenderField element={app} id={app.app_id} field={field} editable={app.editable} lists={lists} changeProps={changeProps}  />
                    </td>)}


                <td>
                  
              
              { app.editable ?<><button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>saveObj(app) }>Valider</button>
              <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(app.app_id,"editable",!app.editable) }>Annuler</button>
              </>:    <>
              <button onClick={() => showCode(app)} className='bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>{"</>"}</button>
              <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(app.app_id,"editable",!app.editable) }>Editer</button>
             
                 <button onClick={() => remove(app.app_id)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button></>
}
                </td>


              </tr>

            )

            }




          </tbody>
        </table>


      </header>
      { appCode ? <AppCode  app={appCode} setAppCode={setAppCode}  /> : <></>}
    </div>
  );
}

export default Applications;
