import { useEffect, useState } from 'react';

import auth from '../../service/auth';
import mapList from '../../service/mapList';
import RenderField from '../../components/RenderField';

function Tokens() {
  const showfields =["token_id","icon","app","token","profile","status"];
  const required =["token","device_id","app_id"];
  const excludes = [ "token_id","enabled","created_at","updated_at","send_at"];
  const [tokens, setTokens] = useState([]);

  const [fieldsNew, setFieldsNew] = useState(required);
  const [lists, setLists] = useState([]);
  const [newToken, setnewToken] = useState(null);
const gets= async () => {

  const resultLists = await auth.api('lists');

  var result = await  auth.api( 'tokens');

 
result = mapList(result,resultLists);

setLists(resultLists);
  setTokens(result);
  if(result.length==0) return;


  setFieldsNew(Object.keys(result[0]).filter(field=>!excludes.includes(field)))


};
const changeProps = (id, field, value) => {
  if (!id) {
    newToken[field] = value;
    setnewToken(mapList([newToken], lists)[0]);

    return;
  }
  setTokens(tokens.map(token => {
    if (token.token_id == id) token[field] = value;


    return token;
  }));

}


const  remove = async (token)=>{
  const result = await  auth.api( 'token/' + token,{
    method:"DELETE"
 

  });

  await gets();


}

const create = async ()=>{
  
var token = {};
 fieldsNew.forEach(field=>{
  var obj = document.getElementById(field);
      console.log(field, obj)
      if (!obj) return;
      var value = obj.value;
if(value) token[field] = value;
 })


token.complete=true;
required.forEach(field=> {
if (!(field in token)) token.complete=false;
});
 if( "profile" in token) {
try {
  token.profile=JSON.parse(token.profile)
}
catch(e){
alert("Error parsing");
  return 
}
 }


 //  if(!app.completed )return; 
 const result = await  auth.api( 'token',{
    method:"PUT",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(token)
  });

  await gets();
  fieldsNew.forEach(field=>{
   document.getElementById(field).value="";
   
     })
}

  

  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">TOKENS</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th  key={field}  className='p-2 mx-4'> {field}  </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>

          <tr >
{showfields.map(field => 
<td   key={field}  className=''> { 
   fieldsNew.includes(field) && newToken ? 
   <RenderField element={newToken} field={field} editable={true} lists={lists} changeProps={changeProps}  />
 :<></>  }  </td>)} 
   
  
<td className='p-1'>
  {
 newToken ?
<>  <button onClick={()=>setnewToken(null)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded '>Annuler</button>
<button onClick={create} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded mx-1'>Valider</button>
</>
 :  <button  onClick={()=>setnewToken({})}  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Créer</button>

}
</td>
   
    </tr>   
    
            {tokens.map(token =>
              <tr   key={token.token_id}  className='p-2'>
                {showfields.map(field => <td  key={field}  className='p-2' >
                <RenderField element={token} id={token.token_id} field={field} editable={token.editable} lists={lists} changeProps={changeProps} />
             
            </td>)}


<td>


<button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-1 rounded'>Editer</button>
<button onClick={()=>remove(token.token)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button>

</td>


              </tr>

            )

            }


      
 
          </tbody>
        </table>


      </header>
    </div>
  );
}

export default Tokens;
