import { useEffect, useState } from 'react';
import auth from '../../service/auth';
import mapList from '../../service/mapList';

function Tickets() {
  const showfields=["token_id","campaign","app","status","created_at"]
  const [tickets, setTickets] = useState([]);



const gets= async () => {

const resultLists = await auth.api('lists');

  var result = await  auth.api( 'tickets');
  result = mapList(result, resultLists);

  setTickets(result);
  if(result.length==0 ) return;
var excludes = ["owner_id","app_id","enabled","created_at","updated_at"];



};

const  remove = async (id)=>{
  const result = await  auth.api( 'ticket/' + id,{
    method:"DELETE"
 

  });

  await gets();


}


  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">TICKETS</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th  key={field}  className='p-2 mx-4'> {field}  </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>
            {tickets.map(ticket =>
              <tr   key={ticket.ticket_id}  className='p-2'>
                {showfields.map(field => <td  key={field}  className='p-2' > {ticket[field]}  </td>)}


<td>

<button onClick={()=>remove(ticket.ticket_id)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button>

</td>


              </tr>

            )

            }
  
 
          </tbody>
        </table>


      </header>
    </div>
  );
}

export default Tickets;
