import axios from "axios";
import url_server from "./url_server";

const API_URL = url_server;

const user = () => {
    if (localStorage.getItem("user"))
        return JSON.parse(localStorage.getItem('user'));
    else return null;
};
const login = async (email, password) => {
    return axios
        .post(API_URL + "api/signin", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.success) {
                localStorage.setItem("user", JSON.stringify( response.data.user));
                localStorage.setItem("token", response.data.token);
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
};

const header = () => {
    const token = localStorage.getItem('token');

    if (token ) {

        return { Authorization: 'Bearer ' + token };

        // for Node.js Express back-end
        // return { 'x-access-token': user.accessToken };
    } else {
        return {};
    }
}

const api = async (endpoint, options) => {
    if (!options) options = {};
    if (!options.headers) options.headers = {};
    options.headers = { ...options.headers, ...header() };

    var result = await fetch(url_server + 'api/' + endpoint, options)

    var json = await result.json();
    return json;
}

export default { api, login, logout,user }