import { useEffect, useState } from 'react';
import RenderField from '../../components/RenderField';


import auth from '../../service/auth';
import mapList from '../../service/mapList';

function Users() {

  const required =["name","email","organization_id"];
  const showfields=["name","organization","email","enabled"];
  const excludes = ["user_id","enabled","created_at","updated_at"];
  const hides = ["password","permissions","created_at","updated_at"];
  const [users, setUsers] = useState([]);
  const [newUser,setNewUser]=useState(null)
const  [lists,setLists]=useState({});
  const [fieldsNew, setFieldsNew] = useState(required);



  const changeProps = (id, field, value) => {
    if (!id) {
      newUser[field] = value;
      setNewUser({...mapList([newUser], lists)[0]});

      return;
    }
    setUsers(users.map(user => {
      if (user.user_id == id) user[field] = value;


      return user;
    }));

  }

const gets= async () => {
  const resultLists = await auth.api('lists');

  var result = await  auth.api( 'users');
result = mapList(result,resultLists);

  setUsers(result);
  setLists(resultLists);
  if(result.length==0 ) return;

  setFieldsNew(Object.keys(result[0]).filter(field=>!excludes.includes(field)))

};

const  resetpwd = async (id)=>{
  const result = await  auth.api( 'user/' + id+"/resetpwd",{
    method:"GET"
  });
  await gets();
}

const  remove = async (id)=>{
  const result = await  auth.api( 'user/' + id,{
    method:"DELETE"
 

  });

  await gets();

}

const create = async ()=>{
  
var user = newUser;
fieldsNew.forEach(field => {
  var obj = document.getElementById(field);
  console.log(field, obj)
  if (!obj) return;
  var value = obj.value;

  if (value) user[field] = value;
})




var required = ["name","organization_id"];
user.complete=true;
required.forEach(field=> {
if (!(field in user)) user.complete=false;
});
  
 //  if(!user.completed )return; 
 const result = await  auth.api( 'user',{
    method:"PUT",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  });

  await gets();
  setNewUser(null);

}


const saveObj = async (user) => {


  var required = ["name"];
  var completed = true;
  required.forEach(field => {
    if (!(field in user)) completed = false;
  });

  if(!completed )return; 

  const response = await auth.api('user', {
    method: "PUT",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  });

  changeProps(user.user_id,"editable",false);

}


  

  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">UTILISATEURS</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th  key={field}  className='p-2 mx-4'> {field}  </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>

          <tr >
{showfields.map(field => 
<td   key={field}  className=''> { 
   fieldsNew.includes(field) && newUser ?      <RenderField element={newUser} field={field} editable={true} lists={lists} changeProps={changeProps}  />  :<></>  }  </td>)} 
   
   
   
<td className='p-1'>

{
 newUser ?
<>  <button onClick={()=>setNewUser(null)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded '>Annuler</button>
<button onClick={create} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded mx-1'>Valider</button>
</>
 :  <button  onClick={()=>setNewUser({})}  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Créer</button>

}
</td>
   
    </tr>   
    
            {users.map(user =>
              <tr   key={user.user_id}  className='p-2'>
                {showfields.map(field => <td  key={field}  className='p-2' > 
  
                
                
                <RenderField element={user} id={user.user_id} field={field} editable={user.editable} lists={lists} changeProps={changeProps} />
         
                 </td>)}


<td>




{ user.editable ?<><button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>saveObj(user) }>Valider</button>
              <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(user.user_id,"editable",!user.editable) }>Annuler</button>
              </>:    <>               
              <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(user.user_id,"editable",!user.editable) }>Editer</button>
              <button onClick={()=>resetpwd(user.user_id)} className='bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Reset Password</button>

<button onClick={()=>remove(user.user_id)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button></>
} 


</td>


              </tr>

            )

            }


      
 
          </tbody>
        </table>


      </header>
    </div>
  );
}

export default Users;
