
const mapList=(result,list,type)=>{
 
    return result.map(obj => {

        Object.keys(obj).forEach(field => {
  
          var parts = field.split("_");
          if (parts.length > 1 && parts[1] == "id" && (parts[0] + "s" in list)) {
            var c = list[parts[0] + "s"].find(e => e[field] == obj[field])
            if (c) {
              obj[parts[0]] = c.name;
              if(c.icon)    obj.icon = c.icon;
            }
          }
        })
        if(type) obj.type=type;
        return obj;
      })
}

export default mapList;