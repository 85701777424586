import { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import auth from "../service/auth";
import Login from "./Login";
import './Layout.css';

const Layout = () => {


  const location = useLocation();
  const [user, setUser] = useState(auth.user());
  const [displayMenu, setDisplayMenu] = useState(false)
  //if(location.pathname.startsWith("/map/"))

  const logout = () => {
    auth.logout();
    setUser(null);
  }
  return <div className="flex flex-col md:flex-row h-screen relative">
    <div className="flex flex-col p-2 shadow md:h-full  bg-gray-500 text-white md:mr-2 fixed md:static w-full md:w-auto  z-10  ">
    <div  className="font-bold  bg-gray-800 p-3 flex justify-between " > <h3 className="font-bold  " >PUSH NOTIFCATION SERVICE   </h3><div  className={"md:hidden " + (displayMenu ? "open" : "") }  onClick={()=>setDisplayMenu(!displayMenu)} id="nav-icon1">
  <span></span>
  <span></span>
  <span></span>
</div></div>

      {user ? <ul className={"flex flex-col p-2  md:block " + ( displayMenu ? " block ":" hidden"   )}>
      <li className="flex flex-row p-2"><Link  onClick={()=>setDisplayMenu(false)}  to="/"    > Dashboard</Link> </li>
        <li className="flex flex-row p-2"><Link onClick={()=>setDisplayMenu(false)}  to="/organizations"    > Organizations</Link> </li>
        <li className="flex flex-row p-2"><Link   onClick={()=>setDisplayMenu(false)} to="/applications"    > Applications</Link> </li>
        <li className="flex flex-row p-2"><Link  onClick={()=>setDisplayMenu(false)} to="/users"    > Users</Link> </li>
        <li className="flex flex-row p-2"><Link  onClick={()=>setDisplayMenu(false)} to="/campaigns"    > Campaigns</Link> </li>
        <li className="flex flex-row p-2"><Link   onClick={()=>setDisplayMenu(false)}to="/tokens"    > Tokens</Link> </li>
        <li className="flex flex-row p-2"><Link  onClick={()=>setDisplayMenu(false)} to="/tickets"    > Tickets</Link> </li>
        <li className="flex flex-row py-2 border-t border-white flex flex-row justify-end"><button onClick={logout} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 my-1 rounded'>Déconnexion</button> </li>
      </ul> : <></>
      }
    </div>
    <div className=" mt-16 md:mt-0  w-full">{user ? <Outlet /> : <Login setUser={setUser} />}
    </div></div>

};

export default Layout;