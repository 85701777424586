
import { useEffect, useState } from 'react';
import Icon from './Icon';

function Content({ content, setContent, icon, name, send_at, darkMode }) {
  const [timePast, setTimePast] = useState("");
  const [json, setJson] = useState({});



  const changeBody = (e) => {
    json.body = e.target.value;
    setJson(json);
    setContent(JSON.stringify(json))
  }
  const changeCriteria = (e) => {
    json.criteria = e.target.value;
    setJson(json);
    setContent(JSON.stringify(json))
  }
  const changeUrl = (e) => {
    json.url = e.target.value;
    setJson(json);
    setContent(JSON.stringify(json))
  }
  const changeSchedule = (value) => {
    json.schedule = value;
    setJson(json);
    setContent(JSON.stringify(json))
  }


  const formatSchedule = () => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    var str = "";
    if (s[5] != "*") str += s[5] + " "
    if (s[3] != "*") str += s[3] + " "
    if (s[4] != "*") str += s[4] + " "
    if (s[2] != "*") str += s[2] + "h "
    if (s[1] != "*") str += s[1] + "min"
    return str
  }
  const getSchedule = (i) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    return s[i];
  }
  const changeScheduleSec = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[0] = e.target.value;
    changeSchedule(s.join(" "));

  }
  const changeScheduleMin = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[1] = e.target.value;
    changeSchedule(s.join(" "));

  }
  const changeScheduleHour = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[2] = e.target.value;
    changeSchedule(s.join(" "));

  }
  const changeScheduleDay = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[3] = e.target.value;
    changeSchedule(s.join(" "));

  }
  const changeScheduleMonth = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[4] = e.target.value;
    changeSchedule(s.join(" "));

  }
  const changeScheduleWeekDay = (e) => {
    var s = (json.schedule ?? "* * * * * *").split(" ")
    s[5] = e.target.value;
    changeSchedule(s.join(" "));

  }


  const changeTitle = (e) => {
    json.title = e.target.value;
    setJson(json);
    setContent(JSON.stringify(json))
  }

  useEffect(() => {
    if (!send_at) setTimePast("-");
    else {
      var tmin = Math.floor((new Date((new Date()).toISOString()).getTime()
        - (new Date(send_at)).getTime()) / 60000);

      if (tmin == 0) setTimePast("maintenant")
      else if (tmin < 60) setTimePast(`il y a ${tmin} min`)
      else {
        var thour = Math.floor(tmin / 60)
        if (thour < 24) setTimePast(`il y a ${thour} h`)
        else {
          var tday = Math.floor(thour / 24)
          setTimePast(`il y a ${tday} j`)
        }
      }
    }

    try {
      var jsonA = JSON.parse(content)
      setJson(jsonA)

    }
    catch (err) {

    }




  }, [content, icon, send_at]);



  return (
    <div className='flex flex-col text-sm'>
      <div className={"flex items-center rounded-2xl p-2 text-xs" + (darkMode ? " bg-black bg-opacity-70 text-white " : " bg-white text-gray-900 shadow-md")}>
        <Icon className="p-1" icon={icon} size={8} />
        <div className='p-1 flex flex-col  w-80'>
          <div className='flex justify-between'>
            {!setContent ? <span className={'p-1 font-bold' + (json.title ? "" : " italic")} >{json.title ?? "Titre du message"}</span>
              :
              <input className='px-1 pb-1  bg-transparent font-bold ' defaultValue={json.title} onChange={changeTitle} placeholder="Titre du message" />
            }
            <span className='text-opacity-50'>{timePast}</span>

          </div>
          {!setContent ?
            <span className={'px-1 pb-1 ' + (json.body ? "" : " italic")} >{json.body ?? "Corps du message"}</span>
            :
            <textarea className='px-1 pb-1 bg-transparent' defaultValue={json.body} onChange={changeBody} placeholder="Corps du message" />
          }
        </div>

      </div>
      {!setContent ?
        <>
          {json.url && <span className={'p-1 '} >Link : {json.url}</span>}
          {json.criteria && <span className={'p-1'} >Critères : {json.criteria}</span>}
          {json.schedule && <span className={'p-1'} >Programmation : {formatSchedule()}</span>}</>
        :
        <>
          <label className='p-1 ' >Url</label>
          <input type="text" className='p-1 bg-white' defaultValue={json.url} onChange={changeUrl} placeholder="DeepLink" />
          <label className='p-1 ' >Critères</label>
          <textarea className='p-1 pb-1 bg-white' defaultValue={json.criteria} onChange={changeCriteria} placeholder="Critère" />
          <label className='p-1 ' >Programmation</label>

          <div className='flex' >

            <div className='flex flex-col' >
              <label className='p-1 ' >WeekDay</label>
              <select value={getSchedule(5)} onChange={changeScheduleWeekDay}   >
                {
                  ["*", "Monday", "Tuesday", "Wednesday", "Thuesday", "Friday", "Saturday", "Sunday"].map(value =>
                    <option value={value} > {value}</option>)
                }
              </select>
            </div>



            <div className='flex flex-col' >
              <label className='p-1 ' >Day</label>
              <select value={getSchedule(3)} onChange={changeScheduleDay}   >
                {
                  ["*", ...Array.from({ length: 31 }, (value, index) => "" + (index + 1))].map(value =>
                    <option value={value}    > {value}</option>)
                }
              </select>
            </div>

            <div className='flex flex-col' >
              <label className='p-1 ' >Month</label>
              <select value={getSchedule(4)} onChange={changeScheduleMonth}   >
                {
                  ["*", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map(value =>
                    <option value={value}    > {value}</option>)
                }
              </select>
            </div>




            <div className='flex flex-col' >
              <label className='p-1 ' >Hour</label>
              <select value={getSchedule(2)} onChange={changeScheduleHour}   >
                {
                  ["*", ...Array.from({ length: 24 }, (value, index) => "" + (index))].map(value =>
                    <option value={value}    > {value}</option>)
                }
              </select>
            </div>

            <div className='flex flex-col' >
              <label className='p-1 ' >Min</label>
              <select value={getSchedule(1)} onChange={changeScheduleMin}   >
                {
                  ["*", ...Array.from({ length: 60 }, (value, index) => "" + (index))].map(value =>
                    <option value={value}    > {value}</option>)
                }
              </select>
            </div>
            <div className='flex flex-col' >
              <label className='p-1 ' >Sec</label>
              <select value={getSchedule(0)} onChange={changeScheduleSec}   >
                {
                  ["*", ...Array.from({ length: 60 }, (value, index) => "" + (index))].map(value =>
                    <option value={value}    > {value}</option>)
                }
              </select>
            </div>
          </div>
        </>
      }</div>
  );
}

export default Content;
