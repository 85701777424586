import { useEffect, useState } from 'react';
import RenderField from '../components/RenderField';
import auth from '../service/auth';
import mapList from '../service/mapList';
import ReactECharts from 'echarts-for-react';
import  Icon   from "../components/Icon";
import Content from '../components/Content';

const ToggleModes = ({darkMode,setDarkMode})=>{
  return ( <div className='flex flex-row items-center justify-center'> 
  Mode
  <div onClick={()=>setDarkMode(true)} className={'rounded-full border flex items-center justify-center w-8 h-8 m-1 border-2' + (darkMode ? " border-black" : "border-gray-200") }> 
  <div className={'rounded-full border flex items-center justify-center w-6 h-6 bg-black '  }> </div>
  </div>
  <div onClick={()=>setDarkMode(false)} className={'rounded-full border flex items-center justify-center w-8 h-8 m-1  border-2' + (!darkMode ? " border-black" : "border-gray-200") }> 
  <div className={'rounded-full border flex items-center justify-center w-6 h-6 bg-white  shadow'  }> </div>
  </div>
   </div>);
}


function Dashboard() {
  const showfields = ["name", "app", "content", "enabled"];
  const required = ["name", "app_id", "content"];
  const excludes = ["campaign_id", "owner_id", "enabled", "created_at", "updated_at", "send_at"];
  const [campaigns, setCampaigns] = useState([]);
  const [apps, setApps] = useState([]);
   const [darkMode,setDarkMode]= useState(false);
  const [newCampaign, setnewCampaign] = useState(null);
  const [lists, setLists] = useState([]);
  var [events, setEvents] = useState([]);



  const gets = async () => {
    const resultLists = await auth.api('lists');

    setLists(resultLists);

     events = await auth.api('events');

   setEvents(events);


 var  result = await auth.api('campaigns');

result = result.filter(c=>c.enabled) .map((campaign)=>{
  campaign.graphIndex="0";
  campaign.options = campaign_Options(campaign);

  return campaign;
})
    result = mapList(result, resultLists);
    setCampaigns(result);

     result = await auth.api('apps');
     result = result.filter(c=>c.enabled) .map((app)=>{
     app.graphIndex="0";
    app.options = app_Options(app);
    return app;
    });
    
    result = mapList(result, resultLists);

    setApps(result);


  };

  const push = async (id) => {
    const response = await auth.api('campaign/' + id + '/push', {
      method: "GET"


    });

    await gets();


  }

  const  fakedataApp = async(app_id)=>{
    const response = await auth.api('app/' + app_id + '/fakedata', {
      method: "GET"


    });

    await gets();
  }

  const  fakedataCampaign = async(campaign_id)=>{
    const response = await auth.api('campaign/' + campaign_id + '/fakedata', {
      method: "GET"


    });

    await gets();
  }



    var list =   [ 
      //Multi-series Bar Chart
      {
      xAxis: {
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {},
      series: [
        {
          type: 'bar',
          data: [23, 24, 18, 25, 27, 28, 25]
        },
        {
          type: 'bar',
          data: [26, 24, 18, 22, 23, 20, 27]
        }
      ]
    },
    // line
     {
      xAxis: {
        data: ['A', 'B', 'C', 'D', 'E']
      },
      yAxis: {},
      series: [
        {
          data: [10, 22, 28, 43, 49],
          type: 'line',
          stack: 'x'
        },
        {
          data: [5, 4, 3, 5, 10],
          type: 'line',
          stack: 'x'
        }
      ]
    } ,
// multiline 
    {
      xAxis: {
        data: ['A', 'B', 'C', 'D', 'E']
      },
      yAxis: {},
      series: [
        {
          data: [10, 22, 28, 43, 49],
          type: 'line',
          stack: 'x',
          areaStyle: {}
        },
        {
          data: [5, 4, 3, 5, 10],
          type: 'line',
          stack: 'x',
          areaStyle: {}
        }
      ]
    }
  ,

  { xAxis: {
    data: ['A', 'B', 'C', 'D', 'E']
  },
  yAxis: {},
  series: [
    {
      data: [10, 22, 28, 23, 19],
      type: 'line',
      smooth: true
    }
  ]},
  // Basic Doughnut Chart
  {
    title: {
      text: 'Utilisation',
      left: 'center',
      top: 'center'
    },
    series: [
      {
        type: 'pie',
        data: [
          {
            value: 335,
            name: 'Uninstalled Apps'
          },
          {
            value: 234,
            name: 'Online Apps'
          },
          {
            value: 1548,
            name: 'Offline Apps'
          }
        ],
        radius: ['40%', '70%']
      }
    ]
  },
  // Rose Chart（Nightingale Chart）
  {
    series: [
      {
        type: 'pie',
        data: [
          {
            value: 100,
            name: 'A'
          },
          {
            value: 200,
            name: 'B'
          },
          {
            value: 300,
            name: 'C'
          },
          {
            value: 400,
            name: 'D'
          },
          {
            value: 500,
            name: 'E'
          }
        ],
        roseType: 'area'
      }
    ]
  },

  //timeline 
  {
    //固定框架的option写这
      baseOption: {
          timeline: {
              //loop: false,      
              axisType: 'category',
              show: true,
              autoPlay: true,
              playInterval: 1500,
              data: ['1990', '1995']         
          },               
          grid: {containLabel: true},
          xAxis: [{
              type: 'value',
              name: '万美元',
              max: 150000
          },],
          yAxis: [{
              type: 'category',
              inverse: true,
          }],
          series: [
              {
                  type: 'bar',
                  
              },
          ]
      },
    
    
    //变化数据写这
      options: [
          //1990
          {
              yAxis: [{
                  data: ['Total Users','Campagne 1', '卢森堡', '瑞典', '挪威']
              }],
              title: {
                  text: "aujourd'hui"
              },
              series: [
                  {
                      data: [38589.18,  33378.44,  29794.08,  28188.52]
                  },

              ]
          },
          //1995
          {
              yAxis: [{
                  data: ['Total Users','Campagne 1','瑞士', '日本', '丹麦']
              }],
              title: {
                  text: 'demain'
              },
              series: [
                  {
                      data: [51189.75,  48712.21,  42516.46,  35477.692]
                  },
              ]
          },
      ]
  }
  
  ];

  // DEEPLINK // IMAGE DANS LES NOTIFS  -  
  // / TOOG Billet Réduit  - 

const listApp= ["Utilisation globale","Progression utilisation","Activité mensuelle / jour", "Usage quotidien","Usage Hebdomadaire"]
const listCampaign= ["Taux d'engagement","Évolution heure par heure "]//,"Évolution selon vague"]
  const app_Options=(app,i)=>{
console.log("eeeeeee" +  app.graphIndex)

const graphIndex = app.graphIndex ?? "0" 


switch (graphIndex){
 
case "0": 
var option =  {
  title: {
    text: 'Utilisation',
    left: 'center',
    top: 'center'
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          value: events.filter( event=> event.app_id==app.app_id && event.name=="UNINSTALLED_APP").length,
          name: events.filter( event=> event.app_id==app.app_id && event.name=="UNINSTALLED_APP").length +' Désinstallations'
        },
        {
          value: events.filter( event=> event.app_id==app.app_id && event.name=="OPEN_APP").length,
          name:  events.filter( event=> event.app_id==app.app_id && event.name=="OPEN_APP").length +' Actifs'
        },
        {
          value: events.filter( event=> event.app_id==app.app_id && event.name=="OFFLINE_APP").length,
          name:  events.filter( event=> event.app_id==app.app_id && event.name=="OFFLINE_APP").length +' Inactifs'
        }
      ],
      radius: ['40%', '70%']
    }
  ]
}
return option;



case "1":
  console.log("case 1 ")
  
  var  days = []
   var TDays = Date.now();
  var inactive_app = [];
  var active_app = [];
  var uninstalled_app= [];
    while ( events.filter( event=> (new Date(event.created_at).getTime()< TDays ) ).length>0)
  {
    var t =  (new Date(TDays));
    days.unshift(t.getDate()+"/"+("0" +(t.getMonth()+1)).substring(-2)  );
    inactive_app.unshift( events.filter( event=> (new Date(event.created_at).getTime()< TDays) && event.app_id==app.app_id  && event.name=="OFFLINE_APP").length)
    active_app.unshift( events.filter( event=> (new Date(event.created_at).getTime()< TDays  && event.app_id==app.app_id && event.name=="OPEN_APP")).length)
    uninstalled_app.unshift( events.filter( event=> (new Date(event.created_at).getTime()< TDays  && event.app_id==app.app_id && event.name=="UNINSTALLED_APP")).length)
    TDays-=24*60*60*1000;
  }
    var option =     {
      title : { 
        text: "",
      },
      legend: {
        data: ['Désinstallations','Utilisateurs actifs',"Utilisateurs inactifs "]
      },
        xAxis: {
          data:days
        },
        yAxis: {},
        series: [
          {
            name: 'Désinstallations',
            data: uninstalled_app,
            type: 'line',
            stack: 'x',
            areaStyle: {}
          },
          {
            name: 'Utilisateurs actifs',
            data: active_app,
            type: 'line',
            stack: 'x',
            areaStyle: {}
          },
          {
            name: "Utilisateurs inactifs ",
            data: inactive_app,
            type: 'line',
            stack: 'x',
            areaStyle: {}
          }
        ]
      } 
      return option;


      case "2":
        console.log("case 1 ")
      
    var  days = []
     var TDays = Date.now();
    var all = [];
    var open_app= [];
      while ( events.filter( event=> (new Date(event.created_at).getTime()< TDays ) && event.app_id==app.app_id && (new Date(event.created_at).getTime()> TDays-24*60*60*1000 )).length>0)
    {
      var t =  (new Date(TDays));
      days.unshift( t.getDate()+"/"+("0" +(t.getMonth()+1)).substring(-2)  );
      all.unshift( events.filter( event=> (new Date(event.created_at).getTime()< TDays ) && event.app_id==app.app_id && (new Date(event.created_at).getTime()> TDays-24*60*60*1000 ) && event.name!="OPEN_APP").length)
      open_app.unshift( events.filter( event=> (new Date(event.created_at).getTime()< TDays )&& event.app_id==app.app_id && (new Date(event.created_at).getTime()> TDays-24*60*60*1000 ) && event.name=="OPEN_APP").length)
      TDays-=24*60*60*1000;
    }
      console.log( events.length )
      var option =     {
          xAxis: {
            data:days
          },
          legend: {
            data: ['Utilisateurs actifs']
          },
          yAxis: {},
          series: [
            {
              name : 'Utilisateurs actifs',
              data: open_app,
              type: 'line',
              stack: 'x'
            }
           
          ]
        } 
        return option;
case "3":


var data  = [];
for(var i=0;i<24;i++){
  data.push({ name: ("0" + i).substring(-2) +"h",
value:  events.filter( event=> (new Date(event.created_at).getHours()== i ) && event.app_id==app.app_id && event.name=="OPEN_APP").length
})
   
}


var option=  {
  series: [
    {
      type: 'pie',
      data,
      roseType: 'area'
    }
  ]
}
return option 
case '4':
var days= ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"];
var data  = [];
for(var i=0;i<7;i++){
  data.push({ name:days[i],
value:  events.filter( event=> (new Date(event.created_at).getDay()== i ) && event.app_id==app.app_id && event.name=="OPEN_APP").length
})
   
}


var option=  {
  series: [
    {
      type: 'pie',
      data,
      roseType: 'area'
    }
  ]
}
return option 
case '7':


}



    return  list[  app.graphIndex ?? 0  ]
  }



  const campaign_Options=(campaign)=>{
 
var pushcount=     events.filter( event=> event.campaign_id==campaign.campaign_id && event.name=="CAMPAIGN_PUSH").length
 var opencount =  events.filter( event=> event.campaign_id==campaign.campaign_id && event.name=="OPEN_BY_NOTIF").length
    switch(campaign.graphIndex)
   {

case "0": 
var option =  {
  title: {
    text: 'TAUX ' + Math.floor(100*opencount/pushcount) + "%",
    left: 'center',
    top: 'center'
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          value:  (pushcount-opencount),
          name: (pushcount-opencount) +' Non Reçus'
        },
        {
          value: opencount,
          name:  opencount +' Engagements'
        }
       
      ],
      radius: ['40%', '70%']
    }
  ]
}
return option;

case "1":
  console.log("case 1 ")
  
  var  days = []
   
   var Thours =0;
   var DatePush=new Date( events.filter(event=>{
return event.campaign_id==campaign.campaign_id && event.name=="CAMPAIGN_PUSH";
  

     })[0].created_at);
   events.forEach(event=>{
  if(  event.campaign_id!=campaign.campaign_id || event.name!="OPEN_BY_NOTIF") return;

  if  (new Date(event.created_at).getTime()>Thours) Thours = new Date(event.created_at).getTime()

   })
  var push_campaign = [];
  var opened = [];
    while ( events.filter( event=> (new Date(event.created_at).getTime()< Thours ) &&  event.campaign_id==campaign.campaign_id  && event.name=="OPEN_BY_NOTIF" ).length>0)
  {
    var t =  (new Date(Thours));
    days.unshift(t.getHours()+ "h"  );
    push_campaign.unshift( events.filter( event=> (new Date(event.created_at).getTime()< Thours) && event.campaign_id==campaign.campaign_id  && event.name=="CAMPAIGN_PUSH").length)
    opened.unshift( events.filter( event=> (new Date(event.created_at).getTime()< Thours  && event.campaign_id==campaign.campaign_id && event.name=="OPEN_BY_NOTIF")).length)
      Thours-=60*60*1000;
  }
    console.log( events.legnth )
    var option =     {
      title : { 
        text: "Envoi Vague "+ campaign.vague +" - " +DatePush.toLocaleString()
      },
      legend: {
        data: ['Envois de notifications','Engagements'],
        top: 'bottom'
      },
        xAxis: {
          data:days
        },
        yAxis: {},
        series: [
          {
            name: 'Envois de notifications',
            data: push_campaign,
            type: 'line',
            smooth: true,
           // stack: 'x',
           // areaStyle: {}
          },
          {
            name: 'Engagements',
            data: opened,
            type: 'line',
            smooth: true,
           // stack: 'x',
           // areaStyle: {}
          }
        ]
      } 
      return option;


   }
   
   
   
    return  list[  campaign.graphIndex ?? 0  ]
  }

const setAppGraphIndex=(app,index)=>{

setApps(  apps.map((app_=>{
  if(app_.app_id == app.app_id) app_.graphIndex = index
  app_.options = app_Options(app_, app.graphIndex)
  return app_;
})   )      )


}

const setCampaignGraphIndex=(campaign,index)=>{

  setCampaigns(  campaigns.map((campaign_=>{
    if(campaign_.campaign_id == campaign.campaign_id) campaign_.graphIndex = index
    campaign_.options = campaign_Options(campaign_, campaign_.graphIndex)
    return campaign_;
  })   )      )
  
  
  }


  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );



  return (
    <div className="App w-full">
      <header className="App-header  w-full">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">DASHBOARD</h3>
 
        <div className='grid  grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4  w-full'>     

        {apps.map(app =>
              <div key={app.app_id} className='p-2 shadow w-full flex flex-col bg-white justify-between'>
                <div className='flex items-start pt-4 px-4'><Icon icon={app.icon} /> <h4 className='m-2 font-bold '>App  {app.name}</h4> </div>
              { events.length>0 &&  <ReactECharts
  option={ 
   app.options
  }
  notMerge={true}
  lazyUpdate={true}
  style={{height:'350px'}}
 // theme={"theme_name"}
  //onChartReady={this.onChartReadyCallback}
  //onEvents={EventsDict}

  opts={{render:'svg'}}
/>
}
    <div className='flex justify-end'>
<select className=''  value= {app.graphIndex} onChange={ (e)=>setAppGraphIndex(app,e.target.value)   }>
  {
    listApp.map( (option,i)=><option value={i} key={i}   > {option}</option> )
  }
</select>
<button onClick={() => fakedataApp(app.app_id)} className=' bg-blue-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Fake Users</button>
                                <button onClick={() => push(app.app_id)} className='hidden bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Push</button>
                                </div>

                            

                </div>
         

            )

            }
            {campaigns.map(campaign =>
              <div key={campaign.campaign_id} className='p-2 shadow w-full flex flex-col justify-between bg-gray-100'>
                <div className='flex items-center pt-2 px-4 font-bold pb-2 flex-col'><h4 className='mb-2 hiddene '>   {campaign.name}</h4>
                <Content  content={campaign.content}  darkMode={true}  icon={campaign.icon} send_at = { campaign.send_at}  name={campaign.name} /> </div>
                <ReactECharts
  option={ 
  campaign.options
  }
  notMerge={true}
  lazyUpdate={true}
  style={{height:'350px'}}
 // theme={"theme_name"}
  //onChartReady={this.onChartReadyCallback}
  //onEvents={EventsDict}

  opts={{render:'svg'}}
/>
    <div className='flex justify-end mt-4'>
<select className=''  value= {campaign.graphIndex}  onChange={ (e)=>setCampaignGraphIndex(campaign,e.target.value)   }>
  {
    listCampaign.map( (option,i)=><option value={i} key={i}   > {option}</option> )
  }
</select>
<button onClick={() => fakedataCampaign(campaign.campaign_id)} className=' bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Fake Push</button>
                                <button onClick={() => push(campaign.campaign_id)} className='bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Push</button>
                                </div>

                </div>
         

            )

            }







            </div>
      </header>
    </div>
  );
}

export default Dashboard;
