
import { useNavigate } from 'react-router-dom';
import auth from '../service/auth';





function Login({ setUser }) {
  const navigate = useNavigate();
  const connexion = async () => {
    var password = document.getElementById('password').value;
    var email = document.getElementById('email').value;

    var result = await auth.login(email, password);

    if (result.success) {
      setUser(result.user);
      navigate("/")
    }
    document.getElementById('password').value = "";
    document.getElementById('email').value = "";

  }

  return (
    <div className="w-full h-full flex flex-row items-center justify-center">
      <div className="shadow flex flex-col p-4">
        <h1>LOGIN</h1>
        <span>Adresse mail</span>
        <input className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-60' type="text" name="email" id="email" placeholder="Saisis ton email" />
        <span>Password</span>
        <input className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-60' type="password" name="password" id="password" placeholder="mot de passe" />
        <button onClick={connexion} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Connexion</button>
      </div>
    </div>
  );
}

export default Login;
