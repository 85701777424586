import { useEffect, useState } from 'react';
import RenderField from '../../components/RenderField';
import auth from '../../service/auth';
import mapList from '../../service/mapList';



const ToggleModes = ({darkMode,setDarkMode})=>{
  return ( <div className='flex flex-row items-center justify-center'> 
  Mode
  <div onClick={()=>setDarkMode(true)} className={'rounded-full border flex items-center justify-center w-8 h-8 m-1 border-2' + (darkMode ? " border-black" : "border-gray-200") }> 
  <div className={'rounded-full border flex items-center justify-center w-6 h-6 bg-black '  }> </div>
  </div>
  <div onClick={()=>setDarkMode(false)} className={'rounded-full border flex items-center justify-center w-8 h-8 m-1  border-2' + (!darkMode ? " border-black" : "border-gray-200") }> 
  <div className={'rounded-full border flex items-center justify-center w-6 h-6 bg-white  shadow'  }> </div>
  </div>
   </div>);
}


function Campaigns() {
  const showfields = ["name", "app", "content", "enabled"];
  const required = ["name", "app_id", "content"];
  const excludes = ["campaign_id", "owner_id", "enabled", "created_at", "updated_at", "send_at"];
  const [campaigns, setCampaigns] = useState([]);
 const [darkMode,setDarkMode]= useState(false);
  const [fieldsNew, setFieldsNew] = useState(required);
  const [newCampaign, setnewCampaign] = useState(null);
  const [lists, setLists] = useState([]);
  const gets = async () => {
    const resultLists = await auth.api('lists');

    var result = await auth.api('campaigns');

    setLists(resultLists);
    result = mapList(result, resultLists);


    setCampaigns(result);
    if (result.length == 0) return;
    setFieldsNew(Object.keys(result[0]).filter(field => !excludes.includes(field)))


  };


  const changeProps = (id, field, value) => {
    if (!id) {
      newCampaign[field] = value;
      setnewCampaign({...mapList([newCampaign], lists)[0]});

      return;
    }
    setCampaigns(campaigns.map(campaign => {
      if (campaign.campaign_id == id) campaign[field] = value;


      return campaign;
    }));

  }


  const push = async (id) => {
    const response = await auth.api('campaign/' + id + '/push', {
      method: "GET"


    });

    await gets();


  }


  const remove = async (id) => {
    const result = await auth.api('campaign/' + id, {
      method: "DELETE"


    });

    await gets();


  }

  const create = async () => {

    var campaign = newCampaign;
    fieldsNew.forEach(field => {
      var obj = document.getElementById(field);
      console.log(field, obj)
      if (!obj) return;
      var value = obj.value;

      if (value) campaign[field] = value;
    })

   

    campaign.complete = true;
    required.forEach(field => {
      if (!(field in campaign)) campaign.complete = false;
    });

    try {
      campaign.content = JSON.parse(campaign.content)
    }
    catch (e) {
      alert("Error parsing");
      return
    }

    if (!campaign.complete) return;
    const result = await auth.api('campaign', {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(campaign)
    });

    await gets();
    setnewCampaign(null);
  }


  const saveObj = async (campaign) => {


    var required = ["name"];
    var completed = true;
    required.forEach(field => {
      if (!(field in campaign)) completed = false;
    });

    if(!completed )return; 

    const response = await auth.api('campaign', {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(campaign)
    });

    changeProps(campaign.campaign_id,"editable",false);

  }

  useEffect(() => {

    gets().then(() => {
      console.log("ok")
    })


  }, []
  );


  return (
    <div className="App">
      <header className="App-header">
        <h3 className="font-bold  bg-gray-800 p-3 text-white m-3">CAMPAGNES</h3>
        <table className="table-auto m-3" >
          <thead>
            <tr>
              {showfields.map(field => <th key={field} className='p-2 mx-4'> {} 
              {field=="content"? <div className='flex items-center justify-between'>{field}<ToggleModes darkMode={darkMode} setDarkMode={setDarkMode} /></div>:field} </th>)}
              <th>ACTIONS </th>
            </tr>
          </thead>
          <tbody>


          <tr >
              {showfields.map(field =>
                <td key={field} className=''> {
                  fieldsNew.includes(field) && newCampaign ?
                    <RenderField element={newCampaign} field={field} editable={true} lists={lists} changeProps={changeProps} darkMode={darkMode} />

                    : <></>}  </td>)}



              <td className='p-1'>
             



                {
 newCampaign ?
<>  <button onClick={()=>setnewCampaign(null)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded '>Annuler</button>
<button onClick={create} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded mx-1'>Valider</button>
</>
 :  <button  onClick={()=>setnewCampaign({content: `{}` })}  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4  rounded w-full'>Créer</button>

}
              </td>

            </tr>


            {campaigns.map(campaign =>
              <tr key={campaign.campaign_id} className='p-2'>
                {showfields.map(field => <td key={field} className='p-2' >

                  <RenderField element={campaign} id={campaign.campaign_id} field={field} editable={campaign.editable} lists={lists} changeProps={changeProps} darkMode={darkMode}/>
                </td>)}


                <td>
                { campaign.editable ?<><button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>saveObj(campaign) }>Valider</button>
              <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(campaign.campaign_id,"editable",!campaign.editable) }>Annuler</button>
              </>:    <>                  <button onClick={() => push(campaign.campaign_id)} className='bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 m-1 rounded'>Push</button>
              <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-1 rounded' onClick={ ()=>changeProps(campaign.campaign_id,"editable",!campaign.editable) }>Editer</button>
              <button onClick={() => remove(campaign.campaign_id)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 m-1 rounded'>Retirer</button></>
} 



                </td>


              </tr>

            )

            }




          </tbody>
        </table>


      </header>
    </div>
  );
}

export default Campaigns;
