import Icon from "./Icon";
import Highlight, { defaultProps } from "prism-react-renderer";
function AppCode({ app, setAppCode }) {



  const exampleCodes = [`npx expo install @react-native-async-storage/async-storage`,

    `import AsyncStorage from '@react-native-async-storage/async-storage';
  
  ...

  /*  Integration Push-service Expo - ${app.name}  */
  async function sendPushService(token,event={})
{ 

  if(token)
  {
    await AsyncStorage.setItem('@token', token)
  }
  else
  {
    token = await AsyncStorage.getItem('@token')
    if(!token) return;
  }
/**  */  
const device_id=token;
const push_api_key = "${app.push_api_key}";
const result = await fetch( "https://push.henritrip.fr/api/token", 
{
  method:"PUT",
  headers: {'Content-Type': 'application/json'},
  body : JSON.stringify({  token, device_id ,event, push_api_key, profile: { gender:"male"  }})
} )
/**  */
}

/* push-service version 0.2 2023.06.01 */
`];


  return (
    <div className={"fixed top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50"}>
      <div className={" bg-white p-4 flex flex-col justify-center rounded-xl"} >
        <div className="flex flex-row px-4  items-center  justify-between font-bold"><Icon icon={app.icon} size={8} ></Icon><span className="p-2"> {app.name} : Code d'intégration Expo</span>
          <span className="rounded-full w-8 h-8 shadow bg-red-600 flex items-center justify-center text-white" onClick={() => setAppCode(null)}>X</span></div>
        <div className={" m-2 p-2 bg-black  text-white rounded"} > <Highlight {...defaultProps} code={exampleCodes[1]} language="js">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={className} style={style}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight></div>
        <pre className="p-4">Recopier le code et le placer dans une fonction qui sera appelé quand : <br />
          - Le token est enregistré pour la première fois <br />
          - Le profil utilisateur a été modifié
        </pre>
      </div>

    </div>
  );
}

export default AppCode;
