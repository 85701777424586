import logo from '../logo.svg';

function Icon({icon,setIcon,name,size}) {



const change=(e)=>{
    const file = e.target.files[0];

    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
        const base64String = reader.result
     //   .replace('data:', '')
     //   .replace(/^.+,/, '');
console.log(base64String)
        setIcon(base64String);
    };
    reader.readAsDataURL(file);
}


    return (
      <div className="flex items-end">
      <img src={icon ?? logo} className={`border border-gray-100 bg-white drop-shadow-xl block rounded-xl ${ size==8 ? "w-8":"w-20" } ${size==8 ?"h-8" : "h-20" }  object-cover  mb-[10px] ` }/>
     { setIcon ? <><input id={name} type="file"  className="hidden" onChange={change}  /><div onClick={(e)=>{ document.getElementById(name).click();   }} className="rounded-full w-8 h-8 flex flex-row justify-center items-center bg-green-500 hover:bg-green-900 ml-[-20px] z-20"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg></div></> :<></>
     }
      </div>
    );
  }
  
  export default Icon;
  