

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Application from "./pages/applications/Application";
import Applications from "./pages/applications/Applications";
import Campaign from "./pages/campaigns/Campaign";
import Campaigns from "./pages/campaigns/Campaigns";
import Layout from "./pages/Layout";
import Organization from "./pages/organizations/Organization";
import Organizations from "./pages/organizations/Organizations";
import Ticket from "./pages/tickets/Ticket";
import Tickets from "./pages/tickets/Tickets";
import Token from "./pages/tokens/Token";
import Tokens from "./pages/tokens/Tokens";
import User from "./pages/users/User";
import MyAccount from "./pages/users/MyAccount";
import Users from "./pages/users/Users";
import Dashboard from "./pages/Dashboard";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route path=""   element={<Dashboard />} />
        <Route path="organizations"   element={<Organizations />} />
        <Route path="organization/:id"   element={<Organization />} />

           <Route path="applications"   element={<Applications />} />
        <Route path="application/:id"   element={<Application />} />   
        <Route path="user/me"   element={<MyAccount />} />
        <Route path="users"   element={<Users />} />
        <Route path="user/:id"   element={<User />} />   

        <Route path="tokens"   element={<Tokens />} />
        <Route path="token/:id"   element={<Token />} />   

        <Route path="campaigns"   element={<Campaigns />} />
        <Route path="campaign/:id"   element={<Campaign />} />   

        <Route path="tickets"   element={<Tickets />} />
        <Route path="ticket/:id"   element={<Ticket />} />   

      
        </Route>
      </Routes>
    </BrowserRouter>
  );
}




